import { FC } from 'react'
import { Box, Flex, Grid } from '@/components/atoms/Grid'
import styled, { withTheme } from 'styled-components'
import useTranslation from 'next-translate/useTranslation'
import { validFor } from '@/theme/validFor'
import { Body2 } from '@/components/atoms/typography'
import { SingleIconProps } from '@/components/atoms/icon'
import TopManufacturerSlider from '@/components/organisms/topManufacturerSlider'
import { HomeBanner, Manufacturer } from '@/network/graphql.g'
import BannersLayout, {
  BannersType
} from '@/components/organisms/bannersLayout'
import { lighten } from 'polished'
import { FreeShippingSmallIconDefault } from '@/components/atoms/icons/FreeShippingSmallIconDefault'
import { DiscountsBigIconDefault } from '@/components/atoms/icons/DiscountsBigIconDefault'
import MainBannerSlider from './mainBannerSlider'
import { ReturnIconDefault } from '@/components/atoms/icons/ReturnIconDefault'

const servicesData: {
  title: string
  IconElement: (props: SingleIconProps) => JSX.Element
}[] = [
  {
    title: 'Menu.Promotions.freeShipping',
    IconElement: FreeShippingSmallIconDefault
  },
  {
    title: 'Menu.Promotions.goodsComplaint',
    IconElement: ReturnIconDefault
  },
  {
    title: 'Menu.Promotions.discounts',
    IconElement: DiscountsBigIconDefault
  }
]

type HomeBannersProps = {
  bannerElements: HomeBanner[]
  promoBanners: HomeBanner[]
  topManufacturerBanners: Manufacturer[]
} & Rest

const HomePageBanners: FC<HomeBannersProps> = ({
  bannerElements,
  promoBanners,
  topManufacturerBanners,
  theme,
  ...rest
}) => {
  const { t } = useTranslation('common')

  return (
    <StyledFlex {...rest} data-cy="homepageBanners">
      <MainBannerSlider bannerElements={bannerElements} />

      <TopManufacturerSlider
        width="100%"
        manufacturers={topManufacturerBanners.filter(
          (manufacturer) => manufacturer.image != null
        )}
      />

      <BannersWrapper mt={{ desktop: '20px', tablet: '20px', mobile: '12px' }}>
        <BannersLayout
          banners={promoBanners}
          gridColumnGap={20}
          gridRowGap={20}
          bannerDst={BannersType.Home}
          data-cy="homepageSecondaryBanners"
        />
        <ServiceGrid
          mt={20}
          display={{ mobile: 'none', desktop: 'grid', tablet: 'grid' }}
          gridTemplateColumns="repeat(3, 1fr)"
          gridColumnGap={0}
          data-cy="homepageServiceWrapper"
        >
          {servicesData.map((service, offset) => (
            <ServiceWrapper
              withBorder={offset === 1}
              key={service.title}
              data-cy="homepageServiceItems"
            >
              <Box mr="15px">
                <service.IconElement
                  color={theme.colors.onBackground}
                  width={'30px'}
                  height={'30px'}
                />
              </Box>
              <StyledBodyText>{t(service.title)}</StyledBodyText>
            </ServiceWrapper>
          ))}
        </ServiceGrid>
      </BannersWrapper>
    </StyledFlex>
  )
}

export default withTheme(HomePageBanners)

const StyledFlex = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const BannersWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 100%;
`

const ServiceWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  ${({ withBorder, theme }) =>
    withBorder &&
    `
      border-left: 1px solid ${lighten(0.94, theme.colors.onBackground)};
      border-right: 1px solid ${lighten(0.94, theme.colors.onBackground)}; 
    `}
`

const ServiceGrid = styled(Grid)`
  border: 1px solid ${(props) => props.theme.colors.onBackground};
  padding: 10px 0;
  border-radius: ${(props) => props.theme.borderRadius}px;
  background-color: ${(props) => props.theme.colors.toolbar};
`

const StyledBodyText = styled(Body2)`
  text-align: center;
  margin: 0;
  ${validFor.tablet`
    font-size: 12px;
  `}
`
